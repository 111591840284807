import { Col, Container, Row } from "react-bootstrap";

const PartnerImages = [
    {
        key: 1,
        image: "/images/partners/peak.png",
        link: "https://peakfinance.io",
    },
    {
        key: 2,
        image: "/images/partners/azoria.png",
        link: "https://azoria.au"
    },
    {
        key: 3,
        image: "/images/partners/metis-rarity.png",
        link: "https://metisrarity.com"
    }
    ,
    {
        key: 4,
        image: "/images/partners/jklmedia.png",
        link: "https://jklmedia.au/"
    }
]

export default function About() {

    return (
        <Container className="about">
            <Row className="about-rows vert-center">
                <Col lg={{ span: 5, offset: 1 }} md={6}>
                    <h1>What is BlockChat DAO?</h1><br />
                    <p><a href="https://blockchatdao.com">BlockChat DAO</a> (BCD) is a decentralised DAO2DAO communication platform built specifically for projects to collaborate,
                        exchange project information, in-dapp NFT trading, Metaverse integration and many more features. BCD will be built on
                        the Metis Andromeda Layer 2 network and Powered by <a href="https://peakfinance.io">Peak Finance</a>. <br /><br />

                        Before BlockChat DAO was known as BCD it was formerly called OzComCoin (OCC), there were some pre-seed investors who helped us begin our journey. After some time we decided to go in a new direction and start a DAO2DAO Communcation platform. These legacy holders will automatically receive an airdropped Ninja and make up 15% of the total supply of the $BCD Token. They will received their allocation of seed tokens which will be vested over 12 months.</p>
                </Col>
                <Col style={{ textAlign: "center" }} lg={{ span: 5, offset: 1 }} md={6}>
                    <img style={{ width: "300px" }} src="/images/partners/bcd.png" />
                </Col>
            </Row>
            <Row className="about-rows vert-center">
                <Col lg={{ span: 5, offset: 1 }} md={6}>
                    <h1>About Metis Ninjas</h1><br />
                    <p>Metis Ninjas are here to protect the metisian ecosystem and help fund the production of the BlockChat Communication platform. Each purchase of a Ninja will result in 3 transactional distributions.
                        <br /><br />
                        Tokenomics for Mint Sale:<br /><br />

                        <ul>
                            <li>10% goes to buy back $BCD token </li>
                            <li>65% goes to the BockChat Treasury</li>
                            <li>25% goes back to the Liquidity wallet</li>
                        </ul><br />

                        Progressive Reward Structure:<br /><br />

                        <ul>
                            <li>1 Ninja: 2 $BCD per week (8 per month - 96 per year)</li>
                            <li>2 Ninjas: 5 $BCD per week (20 per month - 240 per year)</li>
                            <li>3-5 Ninjas: 10 $BCD per week (40 per month - 480 per year)</li>
                            <li>6-10 Ninjas: 20 $BCD per week (80 per month - 960 per year)</li>
                            <li>11-15 Ninjas: 30 $BCD per week (120 per month - 1,440 per year)</li>
                            <li>16-19 Ninjas: 40 $BCD per week (160 per month - 1,920 per year)</li>
                            <li>20+ Ninjas: 100 $BCD per week (400 per month - 4,800 per year)</li>
                        </ul><br />

                        You will be passively earning $BCD just by holding your Metis Ninjas. <br />
                        You will be able to claim your $BCD every month from the Rewards Dashboard. <br /><br />

                        Royalties:<br /><br />

                        <ul>
                            <li>2% buy back of $Metis and hold in the BCD treasury </li>
                            <li>2% buy back of $BCD to be added to the Liquidity </li>
                            <li>2% back to Liquidity wallet</li>
                        </ul><br />

                        There are 5000 uniquely designed Ninjas available. Each Ninja grants you a whitelist spot in the BCD Beta program + premium features of the DApp.</p>
                </Col>
                <Col style={{ textAlign: "center" }} lg={{ span: 5, offset: 1 }} md={6}>
                    <img style={{ width: "300px" }} src="/images/1.png" />
                </Col>
            </Row>
            <Row className="about-rows vert-center">
                <Col lg={{ span: 5, offset: 1 }} md={6}>
                    <h1>How much are Metis Ninjas?</h1><br />
                    <p>Metis Ninjas are broken up into 4 price points:<br /><br />
                        1 - 2 Ninjas = 2.5 Metis each<br />
                        3 - 5 Ninjas = 2 Metis each<br />
                        6 - 10 Ninjas = 1.7 Metis each<br />
                        11+ Ninjas = 1.5 Metis each<br /><br />
                        There are only 5000 in this collection to exist, don’t miss out!
                    </p>
                </Col>
                <Col style={{ textAlign: "center" }} lg={{ span: 5, offset: 1 }} md={6}>
                    <img style={{ width: "300px" }} src="/images/2.png" />
                </Col>
            </Row>
            <Row className="about-rows vert-center">
                <Col lg={{ span: 5, offset: 1 }} md={6}>
                    <h1>Roadmap</h1>
                    <br />
                    <h4>Q2 2022</h4>
                    <br />
                    <p> ✅  Complete smart contract<br />
                        ✅  Complete minting dapp/website. Special thanks to <a href="https://azoria.au">Azoria</a><br />
                        ✅  Partner with <a href="https://monkex.net">Monkex</a><br />
                        ✅  Listed on <a href="https://market.nftapparel.com.au">NFT Apparel Marketplace</a><br />
                        ✅  Fully verified on <a href="https://tofunft.com/collection/metis-ninjas/items">TofuNFT</a><br />
                        ✅  Launched Metis Ninjas June 17th 2022<br />
                        ✅  Airdrop to existing BCD seed investors</p>
                    <br />
                    <h4>Q4 2023</h4>
                    <br />
                    <p> ✅ Finish new Blockchat Website<br />
                        ✅ Start build on Blockchat dApp (Full Build)</p>
                    <br />
                    <h4>Q1 2024</h4>
                    <br />
                    <p> ✅ Ninja integration with BCD Ecosystem </p>
                </Col>
                <Col style={{ textAlign: "center" }} lg={{ span: 5, offset: 1 }} md={6}>
                    <h1>Partners</h1>
                    {PartnerImages.map((e) => (

                        <Col>
                            <a href={e.link}>
                                <img style={{ margin: "20px 0", width: "140px" }} key={e.key} src={e.image} />
                            </a>
                        </Col>
                    ))}
                </Col>
            </Row>
        </Container >
    );
}