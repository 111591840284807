import { Col, Container } from "react-bootstrap";
import styled from "styled-components";


const MintNow = styled.button`
    margin: 50px;
    width: 200px;
    height: 60px;
    border-radius: 50px;
`

export default function Hero() {

    return (
        <Container id="home" style={{ paddingTop: "7vh" }}>
            <Col className="text-center" lg={{ span: 6, offset: 3 }} md={{ span: 6, offset: 3 }} sm={{ span: 6, offset: 3 }}>
                <h1 style={{ fontSize: "46px" }}>The Metis Ninjas are here to protect the BlockChat community</h1>
                <br />
                <p>5000 uniquely generated Ninjas are here to serve as the ultimate statement, a Ninja within the Metis Ecosystem and a BlockChat OG.</p>
                <a href="#mint">
                <   MintNow>Mint Now</MintNow>
                </a>
            </Col>
        </Container>
    );
}